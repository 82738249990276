import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import LinkButton from "../../components/button";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

// Images
import Logo from "../../images/logo.svg";
import Ampoule from "../../images/lightbulb.svg";
import VisuelBanniereDroite from "../../images/visuel-banniere-droite.png";
import VisuelBanniereGauche from "../../images/visuel-banniere-gauche.png";

// types & interfaces
import type { PageProps, RealEstatePartner } from "../../lib/types";

const RealEstatePartnerPage: React.FC<PageProps<RealEstatePartner>> = ({
  data: { page: partner },
  location: { pathname },
}) => (
  <>
    <Layout active="#infos">
      <Seo {...partner.SEO} pathname={pathname} />
      <main className="mt-40">
        <header
          className="flex flex-col items-center relative overflow-y-hidden
          overflow-x-hidden px-5 space-y-8 pb-4 md:px-10 lg:px-15"
        >
          {/* Title */}
          <h1 className="text-xl italic font-bold text-center max-w-2xl lg:text-2xl">
            {partner.pageTitle}
          </h1>

          <div className="flex flex-col items-center space-y-8 max-w-3xl">
            {/* Logo partner & logo MEC */}
            <div
              className="h-20 grid grid-cols-5 place-items-center 
                  gap-2 w-full place-content-around
                  md:px-15
                  md:grid-cols-6
                "
            >
              <div className="col-span-2">
                <img
                  src={Logo}
                  alt={"Logo monemprunt"}
                  className="h-20 w-full object-contain md:w-40"
                />
              </div>

              <div className="text-4xl italic font-bold text-center md:col-span-2">
                X
              </div>

              {partner.logo.localFile.childImageSharp ? (
                <GatsbyImage
                  image={getImage(partner.logo?.localFile.childImageSharp)}
                  alt={`logo ${partner.name}`}
                  className="h-20 col-span-2 md:w-40"
                  objectFit={"contain"}
                />
              ) : (
                <img
                  src={partner.logo.localFile.publicURL}
                  alt={`logo ${partner.name}`}
                  className="h-20  col-span-2 object-contain md:w-40"
                />
              )}
            </div>

            {/* page description */}
            <p className={"text-center max-w-2xl"}>{partner.pageDescription}</p>

            {/* Call-To-Actions */}
            <div className="space-x-4 max-w-3xl">
              {partner.callToAction?.map((cta, i) => (
                <React.Fragment key={i}>
                  <LinkButton
                    size={"sm"}
                    href={cta.link}
                    external
                    label={cta.text}
                  />
                </React.Fragment>
              ))}
            </div>

            <div className="flex space-x-4 justify-center">
              <img src={Ampoule} alt="Ampoule" className="h-10" />
              <p className="inline-flex items-center">{partner.tips}</p>
            </div>
          </div>

          {/* Left & right illustrations  */}
          <div
            className="hidden absolute -bottom-14 top-0 left-[-24rem] mt-0
            xl:block"
          >
            <img
              src={VisuelBanniereGauche}
              alt="Visuel Bannière Gauche"
              className="object-contain object-center h-[446px] w-[712px]"
            />
          </div>

          <div
            className="hidden absolute -bottom-44 -right-8 mt-0
            xl:block
            xxl:-right-4
            "
          >
            <img
              src={VisuelBanniereDroite}
              alt="Visuel Bannière Droite"
              className="object-contain object-center
                h-full w-full
                xxl:h-[722px] xxl:w-[421px]
              "
            />
          </div>
        </header>

        <article
          className="article__MEC bg-light-50 to-white
           pb-[40rem] w-full pt-15  px-5
           md:px-10
           md:pb-80
           lg:px-15
           lg:pb-64
           "
          dangerouslySetInnerHTML={{ __html: partner.details ?? "" }}
        />
      </main>
    </Layout>
  </>
);

export default RealEstatePartnerPage;

export const query = graphql`
  query RealEstatePartner($id: String) {
    page: strapiPartenaireImmobilier(id: { eq: $id }) {
      name
      pageTitle
      pageDescription
      tips
      callToAction: call_to_action {
        link
        text
      }
      SEO {
        description
        slug
        title
      }
      details
      logo {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;
